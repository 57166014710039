<template>
    <div class="card">
        <div class="card-header">
            {{ $t('crud.transporters._create') }}
        </div>
        <div class="card-body">
            <transporters-form-component @submit="create" :record="record"></transporters-form-component>
        </div>
    </div>
</template>
<script>
    import {functions, config} from '../crud/mixin_config';
    import TransportersFormComponent from "./Form";

    export default {
        name: 'transporters-create-component',
        mixins: [functions],
        components: {TransportersFormComponent,},
        data() {
            return {
                crud: config('transporters'),
                record: {
                    // Empty model.
                }
            };
        }
    }
</script>
